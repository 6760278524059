import { yupResolver } from '@hookform/resolvers/yup';
import { Check } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { FormField } from 'pages/Settings/Tabs/common';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useConvoDashboardStore } from 'store';
import { addTeam } from '../common/schema';

const blankTeamInfo = {
  name: '',
};

const dummyTeamInfo = {
  totalReps: 0,
  totalAICalls: 0,
  totalCustomerCalls: 0,
  status: 'ACTIVE',
};

export const TeamAdd = () => {
  const [teamInfo, setTeamInfo] = useState(blankTeamInfo);
  const { user } = useLoggedUser();

  const setTeamList = useConvoDashboardStore((state) => state.setTeamList);
  const teamList = useConvoDashboardStore((state) => state.teamList);

  const { call: createTeam, isLoading: teamCreating } = useApi({
    fetcher: apis.postConvoTeam,
    successMessage: 'Team added successfully',
    onSuccess: (response) => {
      setTeamList([...teamList, { ...response, ...dummyTeamInfo }]);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(addTeam),
    mode: 'onChange',
  });

  const postTeam = (data) => {
    createTeam({ ...data, organizationId: user.organization });
    reset();
  };

  const renderFormFields = () => {
    const fields = [{ name: 'name', label: 'Team Name', type: 'text' }];

    return fields.map((field) => (
      <FormField
        formType="ADD"
        key={field.name}
        field={field}
        register={register}
        errors={errors}
        watch={watch}
        state={teamInfo}
        stateUpdater={setTeamInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />
    ));
  };

  return (
    <ContentBox component="form" onSubmit={handleSubmit(postTeam)}>
      {teamCreating ? <LoadingOverlay /> : null}
      <Stack direction="column" spacing={4}>
        <Typography variant="h6">Create New Team</Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {renderFormFields()}
          <Button
            variant="contained"
            color="success"
            startIcon={<Check />}
            type="submit"
          >
            Create Team
          </Button>
        </Stack>
      </Stack>
    </ContentBox>
  );
};
