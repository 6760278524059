import CallEndIcon from '@mui/icons-material/CallEnd';
import organizationAPI from 'api/api';
import { Caller, DynamicLoadingChecklist } from 'components/CustomMUI';
import { useSnackbar } from 'contexts/SnackbarContext';
import { useApi } from 'hooks';
import useMicDetection from 'hooks/useMicDetection';
import useTimer from 'hooks/useTimer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import useNotificationStore from 'store/useNotificationStore';
import useStore from 'store/useStore';
import styles from './styles/Style.module.css';
const DeepgramCall = ({
  transcript,
  toggleRecording,
  playAudio,
  isRecording,
  isPlayingAudio,
  setIsMuted,
  isMuted,
  isIdle,
  error,
  docId,
  user,
  saveRecording,
}) => {
  const { formattedTime, stopTimer } = useTimer();
  const { selectedPersona, setDocId } = usePersonaStore();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { setNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const { isSpeaking, isIdle: userIsIdle } = useMicDetection();
  const [disabled, setDisabled] = useState(false);
  const { pathName, clearPathName, setCallEnded, callEnded, clearCallEnded } =
    useStore();
  const [isAssignmentPage, setIsAssignmentPage] = useState(false);
  const { call: ConvRubricCall } = useApi({
    fetcher: organizationAPI.generateConvRubric,
    successMessage: 'Record generated successfully!',
  });

  const { call: PeerComparisonCall } = useApi({
    fetcher: organizationAPI.genratePeerComparison,
    successMessage: 'Record generated successfully!',
  });

  const { call: transcriptCall } = useApi({
    fetcher: organizationAPI.getConvRubric,
    successMessage: 'Call Details Fetched Successfully!',
  });

  const { call: ConvoAssignmentCall } = useApi({
    fetcher: organizationAPI.updateConvoAssignment,
    successMessage: 'ConvoAssignment updated successfully!',
  });

  const [checklistItems, setChecklistItems] = useState([
    {
      id: 'convRubric',
      label: 'Conversation Rubric Generated',
      isComplete: false,
      apiCall: ConvRubricCall,
    },
    {
      id: 'peerComparison',
      label: 'Peer Comparison Generated',
      isComplete: false,
      apiCall: PeerComparisonCall,
    },
    {
      id: 'transcript',
      label: 'Transcript Fetched',
      isComplete: false,
      apiCall: transcriptCall,
    },
  ]);

  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';

  const imageUrl =
    selectedPersona.avatar && selectedPersona.avatar !== 'invalid'
      ? selectedPersona.avatar
      : selectedPersona.gender === 'male'
      ? maleImage
      : femaleImage;

  useEffect(() => {
    if (pathName.includes('/assignment')) {
      setIsAssignmentPage(true);
    } else {
      setIsAssignmentPage(false);
    }
    if (callEnded) {
      clearCallEnded();
    }
  }, []);

  useEffect(() => {
    if (isIdle) {
      handleCallEnd();
      stopTimer();
      setNotification(
        'Something went wrong. Please try again. ' || error,
        'error'
      );
    }
  }, [isIdle, navigate, error]);

  const updateChecklistItem = (itemId, isComplete) => {
    setChecklistItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isComplete } : item
      )
    );
  };

  const handleCallEnd = async () => {
    if (!docId) {
      setDisabled(true);
      navigate(-1);
      return;
    }
    setCallEnded();
    setIsMuted(true);
    setDisabled(true);
    setDocId(docId);
    const data = { docId };
    setIsLoading(true);

    try {
      await saveRecording();
      const convRubricResponse = await checklistItems[0].apiCall(data);
      if (!convRubricResponse) {
        setNotification(
          'Scorecard not generated. The call was too short; a conversation must be active for at least 30 seconds to generate a scorecard.',
          'warning'
        );
        throw new Error(
          'Scorecard not generated. The call was too short; a conversation must be active for at least 30 seconds to generate a scorecard.'
        );
      }
      updateChecklistItem('convRubric', true);

      const peerComparisonResponse = await checklistItems[1].apiCall(data);
      if (!peerComparisonResponse) {
        setNotification('Failed to generate peer comparison response', 'error');
        throw new Error('Failed to generate peer comparison response');
      }
      updateChecklistItem('peerComparison', true);

      const transcriptResponse = await checklistItems[2].apiCall(docId);
      if (!transcriptResponse) {
        setNotification(
          'Transcript not generated. The call was too short; a conversation must be active for at least 30 seconds to generate a transcript.',
          'warning'
        );
        throw new Error(
          'Scorecard not generated. The call was too short; a conversation must be active for at least 30 seconds to generate a scorecard.'
        );
      }
      updateChecklistItem('transcript', true);

      if (isAssignmentPage) {
        try {
          const tempData = {
            data: { personaId: selectedPersona._id },
            userAssignmentId: selectedPersona.userAssignmentId,
          };

          const convoAssignmentResponse = await ConvoAssignmentCall(tempData);
          console.log(convoAssignmentResponse);
        } catch (error) {
          console.log(error);
        }
      }

      setTimeout(() => {
        setIsLoading(false);
        clearPathName();
        navigate(`/airoleplaycall/calldetails`);
      }, 1000);
    } catch (err) {
      setIsLoading(false);
      const errorMessage =
        err.message || 'Something went wrong. Please try again.';

      if (errorMessage.includes('Call duration was too short')) {
        showSnackbar(
          'The call was too short; a conversation must be active for at least 30 seconds to generate a scorecard.'
        );
        setNotification(
          'The call was too short; a conversation must be active for at least 30 seconds to generate a scorecard.',
          'warning'
        );
      } else {
        showSnackbar(errorMessage);
        setNotification(errorMessage, 'error');
      }
      clearPathName();
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!isSpeaking && userIsIdle && !isRecording && !disabled) {
      setIsLoading(false);
      setNotification(
        'User is idle for too long. Please try again.',
        'warning'
      );
      clearPathName();
      setCallEnded();
      navigate(-1);
    }
  }, [isSpeaking, userIsIdle]);

  return (
    <div className={styles.ongoing_call_container}>
      <DynamicLoadingChecklist
        isLoading={isLoading}
        checklistItems={checklistItems}
        title="Processing Call Data..."
      />
      <h1>Ongoing Call</h1>
      <div className={styles.caller_container}>
        {user && (
          <Caller
            image={'/user.jpg'}
            userName={`${user.firstName} ${user.lastName}`}
            designation={'Sales Development Representative'}
            handle={'ZipRecruiter'}
            status={'Sales Representative'}
            bgColor={'#0088FF'}
            borderColor={'#0088FF'}
            toggleRecording={toggleRecording}
            playAudio={playAudio}
            isRecording={isRecording}
          />
        )}

        <Caller
          image={imageUrl}
          userName={selectedPersona.name}
          designation={selectedPersona.role}
          handle={selectedPersona.company}
          status={selectedPersona.mood}
          bgColor={'#00706C'}
          borderColor={'#00706C'}
          isPersona={true}
          isPlayingAudio={isPlayingAudio}
          setIsMuted={setIsMuted}
          isMuted={isMuted}
        />
      </div>
      <div className={styles.subtitle_btn_group}>
        <div className={styles.subtitle}>
          <div className={styles.typewriter}>
            <p>{transcript}</p>
          </div>
        </div>
        <button
          className={styles.end_call}
          onClick={() => {
            stopTimer();
            handleCallEnd();
          }}
          disabled={disabled}
        >
          <p>{formattedTime}</p>
          <div className={styles.call_end_icon}>
            <CallEndIcon />
            <p>End Call</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default DeepgramCall;
