import { create } from 'zustand';

export const useConvoDashboardStore = create((set) => ({
  deleteDialog: { isOpen: false, forItem: '', onOk: () => {} },
  editDialog: { isOpen: false, forItem: '', onOk: () => {}, data: {} },
  teamList: [],
  salesRepList: [],
  adminList: [],

  setTeamList: (teamList) => set({ teamList }),
  setAdminList: (adminList) => set({ adminList }),
  setSalesRepList: (salesRepList) => set({ salesRepList }),

  updateTeamItem: (updatedItem) =>
    set((state) => ({
      teamList: state.teamList.map((item) =>
        item._id === updatedItem._id ? { ...item, ...updatedItem } : item
      ),
    })),
  updateSalesRepItem: (updatedItem) => {
    return set((state) => ({
      salesRepList: state.salesRepList.map((item) =>
        item._id === updatedItem._id ? { ...item, ...updatedItem } : item
      ),
    }));
  },
  updateAdminItem: (updatedItem) =>
    set((state) => ({
      adminList: state.adminList.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item
      ),
    })),

  setDeleteDialog: ({ isOpen, forItem, onOk }) => {
    set((state) => ({
      deleteDialog: {
        ...state.deleteDialog,
        isOpen,
        forItem,
        onOk,
      },
    }));
  },
  setEditDialog: ({ isOpen, forItem, onOk, data }) => {
    set((state) => ({
      editDialog: {
        ...state.deleteDialog,
        isOpen,
        forItem,
        onOk,
        data,
      },
    }));
  },
}));
